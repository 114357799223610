<template>
  <div class="ces-main" style="height: 100%">
    <div class="boxbutton">
      <el-row>
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <!--<el-button type="primary" plain size="mini" v-if="signforSearchFlag" @click="signforSearch">查询</el-button>-->
            <el-button type="primary" plain size="mini" v-if="signforFlag" @click="signfor">签收</el-button>
            <el-button type="primary" plain size="mini" v-if="signforevaluateFlag" @click="signforevaluate">评价</el-button>
            <el-button type="primary" plain size="mini" @click="reviewComments">查看评价</el-button>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-input
              maxlength="50"
              placeholder="请输入运单号"
              v-model="AccurateAndUnique_orderNo"
              size="mini"
              style="width: 250px"
              class="input-with-input"
            >
              <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
            </el-input>
            <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
          </div>
        </el-col>
        <el-drawer
          title="客户签收管理高级查询条件"
          :before-close="cancelForm"
          :visible.sync="dialog"
          direction="rtl"
          custom-class="demo-drawer"
          ref="drawer"
        >
          <div class="demo-drawer__content">
            <el-row>
              <el-col>
                <div class="grid-content bg-purple">
                  <el-card class="box-card">
                    <div>
                      <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                        <el-form-item label="运单状态" prop="status">
                          <el-select v-model="dataForms.status" placeholder="请选择运单状态">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="正常" value="0"></el-option>
                            <el-option label="撤单" value="1"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="物流状态" prop="transStatus">
                          <el-select v-model="dataForms.transStatus" placeholder="请选择物流状态状态">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="初始" value="0"></el-option>
                            <el-option label="已发布" value="2"></el-option>
                            <el-option label="已揽收" value="4"></el-option>
                            <el-option label="派送中" value="6"></el-option>
                            <el-option label="已送达" value="8"></el-option>
                            <el-option label="已签收" value="10"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="评价状态" prop="commentFlag">
                          <el-select v-model="dataForms.commentFlag" placeholder="请选择评价状态">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="未评" value="0"></el-option>
                            <el-option label="已评" value="1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                      <div slot="footer" class="dialog-footer" style="text-align: center">
                        <el-button size="mini" v-if="signforSearchFlag" type="primary" @click="handleClose" :loading="loading">{{
                          loading ? '提交中 ...' : '查 询'
                        }}</el-button>
                        <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-drawer>
      </el-row>
    </div>
    <div class="tableMain">
      <ces-table
        size="mini"
        :isSelection="true"
        :isIndex="true"
        :isPagination="true"
        :isHandle="true"
        v-loading="loading"
        :tableData="tableData"
        :tableCols="tableCols"
        :pagination="pagination"
        @refresh="handleCurrentChange"
        @setCellColor="setCellColor"
        ref="cesTable"
      >
      </ces-table>
    </div>
    <el-dialog title="签收评价" :visible.sync="dialog_customerSign" width="82%" :modal="true" center :before-close="handleClose_customerSign">
      <el-card class="box-card" :body-style="{ padding: '10px' }">
        <div slot="header">
          <span style="font-size: 14px">评价</span>
        </div>
        <div>
          <el-form :model="customerSign" ref="customerSign" label-width="130px" class="demo-ruleForm" size="mini" label-position="left">
            <el-row>
              <el-col :span="24">
                <el-form-item label="运单编号" prop="orderNo">
                  <el-link :underline="false" type="primary">{{ customerSign.orderNo }}</el-link>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="商品评分" prop="orderStart">
                  <el-rate v-model="customerSign.orderStart" :colors="colors" :disabled="customerSignRate[0]" @change="orderStartChange"></el-rate>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="orderStartListFlag">
                <el-checkbox-group v-model="checkboxGroup1" size="small">
                  <el-checkbox class="mycheckbox" v-for="ch in orderStartList" :label="ch" :key="ch" border></el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="店家服务评分" prop="tioStart">
                  <el-rate v-model="customerSign.tioStart" :colors="colors" :disabled="customerSignRate[1]"></el-rate>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="快递员评分" prop="driverStart">
                  <el-rate v-model="customerSign.driverStart" :colors="colors" :disabled="customerSignRate[2]" @change="driverStartChange"></el-rate>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="driverStartListFlag">
                <el-checkbox-group v-model="checkboxGroup2" size="small">
                  <el-checkbox class="mycheckbox" v-for="ch in driverStartList" :label="ch" :key="ch" border></el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="快递配送速度评分" prop="speedStart">
                  <el-rate v-model="customerSign.speedStart" :colors="colors" :disabled="customerSignRate[3]" @change="speedStartChange"></el-rate>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="speedStartListFlag">
                <el-checkbox-group v-model="checkboxGroup3" size="small">
                  <el-checkbox class="mycheckbox" v-for="ch in speedStartList" :label="ch" :key="ch" border></el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="物品包装评分" prop="packagStart">
                  <el-rate v-model="customerSign.packagStart" :colors="colors" :disabled="customerSignRate[4]" @change="packagStartChange"></el-rate>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="packagStartListFlag">
                <el-checkbox-group v-model="checkboxGroup4" size="small">
                  <el-checkbox class="mycheckbox" v-for="ch in packagStartList" :label="ch" :key="ch" border></el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row v-if="customerFeedbackFlag">
              <el-col :span="24">
                <el-form-item label="客户反馈" prop="customerFeedback">
                  <el-tag v-for="item in remarkList" :key="item" style="margin-top: 5px; margin-right: 5px" type="danger" effect="dark">
                    {{ item }}
                  </el-tag>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="relation-item">
                  请填写您的评价，我们将会及时整改
                  <div style="margin: 20px 0"></div>
                  <el-form-item label="描述" prop="commentDesc">
                    <el-input type="textarea" :rows="4" :disabled="customerCommEntFlag" placeholder="请输入内容" v-model="customerSign.commentDesc">
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer" v-if="save_customerSignFlag" style="text-align: center">
        <el-button type="primary" @click="save_customerSign">提 交</el-button>
        <el-button @click="handleClose_customerSign">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//引入组件
import cesTable from '../../components/common/table.vue'
import Drawer from '../../components/common/drawer.vue'
import axios from 'axios'
import arrayQuery from '../../components/arrayQuery.js'
import qs from 'qs'
import 'element-ui/lib/theme-chalk/index.css'
export default {
  data() {
    return {
      table: false,
      dialog: false,
      loading: false,
      dialog_customerSign: false,
      customerCommEntFlag: false,
      colors: ['red', '#F7BA2A', '#FF9900'],
      orderStartList: [],
      orderStartListFlag: false,
      driverStartList: [],
      driverStartListFlag: false,
      speedStartList: [],
      speedStartListFlag: false,
      packagStartList: [],
      packagStartListFlag: false,
      checkboxGroup1: [],
      checkboxGroup2: [],
      checkboxGroup3: [],
      checkboxGroup4: [],
      remarkList: [],
      customerFeedbackFlag: false,
      AccurateAndUnique_orderNo: '',
      dataForms: {
        orderNo: '',
        status: '',
        transStatus: '',
        commentFlag: '',
      },
      customerSign: {
        orderNo: '',
        orderStart: 5,
        tioStart: 5,
        driverStart: 5,
        speedStart: 5,
        packagStart: 5,
        commentDesc: '',
      },
      formLabelWidth: '80px',
      timer: null,
      apiId: 'null',
      fieldList: [],
      // 表格
      tableData: [], //表格数据
      tableCols: [
        //表格列数据
        { label: '运单编号', prop: 'orderNo', width: '180', align: 'center' },
        {
          label: '送达时间',
          prop: 'deliveryTime',
          width: '160',
          align: 'center',
          sortable: true,
          formatter: (row, column) => {
            if (row.deliveryTime == null) {
              return ''
            } else {
              return this.$moment(row.deliveryTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        {
          label: '签收时间',
          prop: 'signTime',
          width: '160',
          align: 'center',
          sortable: true,
          formatter: (row, column) => {
            if (row.signTime == null) {
              return ''
            } else {
              return this.$moment(row.signTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        {
          label: '单据类型',
          prop: 'status',
          align: 'center',

          formatter: row => {
            if (row.status == 0) {
              return '正常'
            } else if (row.status == 1) {
              return '撤单'
            } else {
              return ''
            }
          },
        },
        {
          label: '物流状态',
          prop: 'transStatus',
          align: 'center',
          formatter: row => {
            if (row.transStatus == 0) {
              return '初始'
            } else if (row.transStatus == 2) {
              return '已发布'
            } else if (row.transStatus == 4) {
              return '已揽收'
            } else if (row.transStatus == 6) {
              return '派送中'
            } else if (row.transStatus == 8) {
              return '已送达'
            } else if (row.transStatus == 10) {
              return '已签收'
            } else {
              return ''
            }
          },
        },
        {
          label: '评价状态',
          prop: 'commentFlag',
          align: 'center',
          formatter: row => {
            if (row.commentFlag == 0) {
              return '未评'
            } else if (row.commentFlag == 1) {
              return '已评'
            } else {
              return ''
            }
          },
        },
        { label: '总件数', prop: 'pkgQty', align: 'center', width: '120' },
        { label: '总重量（kg）', prop: 'weight', align: 'center', width: '120' },
        { label: '总体积（m³）', prop: 'volume', align: 'center', width: '120' },
        { label: '收货方名称', prop: 'vendeeName', align: 'center', width: '120' },
        { label: '收货方地址', prop: 'vendeeAddress', align: 'center', width: '120' },
        { label: '收货方联系人', prop: 'vendeeLinkman', align: 'center', width: '120' },
        { label: '收货方电话', prop: 'vendeeMobile', align: 'center', width: '120' },
        { label: '发货方名称', prop: 'consignName', align: 'center', width: '120' },
        { label: '发货方地址', prop: 'consignAddress', align: 'center', width: '120' },
        { label: '发货方联系人', prop: 'consignLinkman', align: 'center', width: '120' },
        { label: '发货方电话', prop: 'consignMobile', align: 'center', width: '120' },
        { label: '司机名称', prop: 'driverName', align: 'center', width: '120' },
        { label: '车牌号', prop: 'truckNo', align: 'center', width: '120' },
      ],
      pagination: {
        //分页数据
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      merge: [],
      signforSearchFlag: true,
      signforFlag: true,
      signforevaluateFlag: true,
      customerSignRate: [false, false, false, false, false],
      save_customerSignFlag: false,
    }
  },

  components: {
    //引入组件后注册组件
    cesTable,
    Drawer,
  },
  mounted() {
    this.getTableAndForm()
    let parentId = '2_2_4'
    let currentPageButtons = arrayQuery.text(parentId)
    let _this = this
    _this.currentPageButtons = currentPageButtons
    //查询按钮权限
    let signforSearch = currentPageButtons.find(item => {
      return item.menuId == '2_2_4_1'
    })
    if (signforSearch == undefined) {
      _this.signforSearchFlag = false
    } else {
      _this.signforSearchFlag = true
    }
    //签收按钮权限
    let signfor = currentPageButtons.find(item => {
      return item.menuId == '2_2_4_2'
    })
    if (signfor == undefined) {
      _this.signforFlag = false
    } else {
      _this.signforFlag = true
    }
    //评价按钮权限
    let signforevaluate = currentPageButtons.find(item => {
      return item.menuId == '2_2_4_3'
    })
    if (signforevaluate == undefined) {
      _this.signforevaluateFlag = false
    } else {
      _this.signforevaluateFlag = true
    }

    _this.getCustData()
  },
  methods: {
    getCustData() {
      axios.post(`${this.$apiPath}/api/urban/comment/getOptional`).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].type == 1) {
            this.orderStartList = res.data[i].values
          } else if (res.data[i].type == 2) {
            this.driverStartList = res.data[i].values
          } else if (res.data[i].type == 3) {
            this.speedStartList = res.data[i].values
          } else if (res.data[i].type == 4) {
            this.packagStartList = res.data[i].values
          }
        }
      })
    },
    orderStartChange(val) {
      if (val < 3) {
        this.orderStartListFlag = true
      } else {
        this.orderStartListFlag = false
        this.checkboxGroup1.length = 0
      }
    },
    driverStartChange(val) {
      if (val < 3) {
        this.driverStartListFlag = true
      } else {
        this.driverStartListFlag = false
        this.checkboxGroup2.length = 0
      }
    },
    speedStartChange(val) {
      if (val < 3) {
        this.speedStartListFlag = true
      } else {
        this.speedStartListFlag = false
        this.checkboxGroup3.length = 0
      }
    },
    packagStartChange(val) {
      if (val < 3) {
        this.packagStartListFlag = true
      } else {
        this.packagStartListFlag = false
        this.checkboxGroup4.length = 0
      }
    },
    setCellColor: function (e, callback) {
      //e.row：表格那一行的数据；  e.column：表格单元格那一列的信息
      if (e.row.commentFlag === 1) {
        callback({ background: '#16a085', color: '#ffffff' })
      } else {
        callback({})
      }
    },
    handleClose_customerSign() {
      this.dialog_customerSign = false
      this.customerCommEntFlag = false
      this.$refs.customerSign.resetFields()
      this.customerSign.orderNo = ''
      this.customerSign.orderStart = 5
      this.customerSign.tioStart = 5
      this.customerSign.driverStart = 5
      this.customerSign.speedStart = 5
      this.customerSign.packagStart = 5
      this.customerSign.commentDesc = ''
      this.customerSignRate = [false, false, false, false, false]
      this.orderStartListFlag = false
      this.driverStartListFlag = false
      this.speedStartListFlag = false
      this.packagStartListFlag = false
      this.checkboxGroup1 = []
      this.checkboxGroup2 = []
      this.checkboxGroup3 = []
      this.checkboxGroup4 = []
      this.customerFeedbackFlag = false
      this.save_customerSignFlag = false
    },
    save_customerSign() {
      const checkboxGroup1 = this.checkboxGroup1
      const checkboxGroup2 = this.checkboxGroup2
      const checkboxGroup3 = this.checkboxGroup3
      const checkboxGroup4 = this.checkboxGroup4
      let sumData = []
      let remark = ''
      sumData = sumData.concat(checkboxGroup1).concat(checkboxGroup2).concat(checkboxGroup3).concat(checkboxGroup4)
      if (sumData.length == 0) {
        remark = ''
      } else {
        remark = sumData.join('-')
      }
      const data = {}
      data.orderNo = this.customerSign.orderNo
      data.orderStart = this.customerSign.orderStart
      data.tioStart = this.customerSign.tioStart
      data.driverStart = this.customerSign.driverStart
      data.speedStart = this.customerSign.speedStart
      data.packagStart = this.customerSign.packagStart
      data.commentDesc = this.customerSign.commentDesc
      data.remark = remark
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/comment/addComment`,
        data: data,
      }).then(res => {
        if (res.data.success == true) {
          this.$message({
            message: '评价成功',
            type: 'success',
          })
          this.handleClose_customerSign()
          this.signforSearch()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
          this.handleClose_customerSign()
        }
      })
    },
    //此方法是点击分页时触发的查询，
    handleCurrentChange() {
      this.signforSearch()
    },
    //获取表格和表单数据
    async getTableAndForm() {
      this.signforSearch()
    },
    handleQuery() {
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      //快捷查询
      this.loading = true
      const data = new URLSearchParams()
      data.append('orderNo', this.AccurateAndUnique_orderNo)
      data.append('pageSize', this.pagination.pageSize)
      data.append('pageNo', this.pagination.pageNum)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/querysignBills`,
        data: data,
      }).then(res => {
        this.tableData = res.data.items
        this.pagination.total = res.data.total
        this.loading = false
      })
    },
    // 获取表格勾选数据
    selectionLineChangeHandle(val) {
      this.merge = this.$refs.cesTable.multipleSelection
    },
    //订单查询
    signforSearch() {
      this.loading = true
      const data = new URLSearchParams()
      data.append('orderNo', this.dataForms.orderNo)
      data.append('status', this.dataForms.status)
      data.append('transStatus', this.dataForms.transStatus)
      data.append('commentFlag', this.dataForms.commentFlag)
      data.append('pageSize', this.pagination.pageSize)
      data.append('pageNo', this.pagination.pageNum)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/querysignBills`,
        data: data,
      }).then(res => {
        this.tableData = res.data.items
        this.pagination.total = res.data.total
        this.loading = false
      })
    },
    signforSearch_Multiple() {
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      this.signforSearch()
    },
    //签收
    signfor() {
      this.selectionLineChangeHandle()
      var preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '签收操作必须选中一条数据',
        })
      } else {
        for (var i = 0; i < this.merge.length; i++) {
          preOrderNos.push(this.merge[i].orderNo)
        }
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/waybill/signBill`,
          data: preOrderNos,
        }).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '签收成功',
              type: 'success',
            })
            this.signforSearch()
          } else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        })
      }
    },
    //评价
    signforevaluate() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '评价操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '评价操作只能选择单条数据进行操作',
        })
      } else {
        if (this.merge[0].commentFlag == 1) {
          this.$message({
            type: 'info',
            message: '该订单已评价！',
          })
          return false
        } else {
          this.customerFeedbackFlag = false
          this.dialog_customerSign = true
          this.save_customerSignFlag = true
          this.customerSign.orderNo = this.merge[0].orderNo
        }
      }
    },
    //查看评价
    reviewComments() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '查看评价操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '查看评价操作只能选择单条数据进行操作',
        })
      } else {
        const orderNo = new URLSearchParams()
        orderNo.append('orderNo', this.merge[0].orderNo)
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/comment/getComment`,
          data: orderNo,
        }).then(res => {
          if (res.data.data == null) {
            this.$message({
              message: '订单' + this.merge[0].orderNo + '还未评价',
              type: 'info',
            })
          } else {
            this.customerCommEntFlag = true
            this.customerSign.orderNo = res.data.data.orderNo
            this.customerSign.orderStart = res.data.data.orderStart
            this.customerSign.tioStart = res.data.data.tioStart
            this.customerSign.driverStart = res.data.data.driverStart
            this.customerSign.speedStart = res.data.data.speedStart
            this.customerSign.packagStart = res.data.data.packagStart
            this.customerSign.commentDesc = res.data.data.commentDesc
            this.remarkList = res.data.data.remark.split('-')
            this.customerSignRate = [true, true, true, true, true]
            this.save_customerSignFlag = false
            this.customerFeedbackFlag = true
            this.dialog_customerSign = true
          }
        })
      }
    },
    handleClose(done) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.timer = setTimeout(() => {
        // 动画关闭需要一定的时间
        setTimeout(() => {
          this.loading = false
        }, 400)
        this.signforSearch_Multiple()
        // 动画关闭需要一定的时间
        this.dialog = false
      }, 2000)
    },
    cancelForm() {
      this.loading = false
      this.dialog = false
      clearTimeout(this.timer)
    },
  },
}
</script>
<style>
.tableMain .ces-table-page {
  height: 100%;
}
.tableMain .ces-table {
  height: 100%;
}
.tableMain .el-table th {
  padding: 0;
  height: 35px;
}
.tableMain .el-table td {
  padding: 0;
  height: 35px;
}
</style>
<style scoped>
.boxbutton {
  width: 100%;
  height: 40px;
}
.tableMain {
  width: 100%;
  height: 82%;
}
.mycheckbox {
  margin-right: 5px;
}
</style>
